<template>
  <button
    type="button"
    class="settings-menu-button"
    :class="{ 'settings-menu-button--disabled': button.disabled  }"
    @click.stop="button.action"
  >
    <span class="settings-menu-button__font settings-menu-button__font--main">
      {{ button.name }}
    </span>

    <span class="settings-menu-button__addition">
      <span class="settings-menu-button__font settings-menu-button__font--addition">
        {{ button.addition }}
      </span>
    </span>

    <CIcon name="arrow-menu-to-right" class="settings-menu-button__icon" />
  </button>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "SettingsMenuButton",
  components: { CIcon },
  props: {
    button: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.settings-menu-button {
  display: flex;
  justify-content: space-between;
  padding: em(16) em(16) em(17);
  border-top: 1px solid $app-gray-2;


  &:last-child {
    border-bottom: 1px solid $app-gray-2;
  }

  &__addition {
    margin-left: auto;
    margin-right: em(6);
  }

  &__font {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--main {
      color: $app-gray-21;
      font-size: em(16);
    }

    &--addition {
      color: $app-font-color-placeholder;
      font-size: em(14);
    }
  }

  &__icon {
    width: em(17);
    height: em(17);
    color: $app-gray-5;
  }

  &--disabled {
    pointer-events: none;
  }
}
</style>
