<template>
  <b-row class="page-settings-menu">
    <b-col>
      <PageHeader :title="$t('general.settingsAccount.title')" />

      <div v-for="group in groups" :key="group.id">
        <div class="page-settings-menu__group-title" v-if="group.children.length">
          <span
            class="page-settings-menu__font page-settings-menu__font--group-title"
          >
            {{ group.name }}
          </span>
        </div>

        <div class="page-settings-menu__settings" v-if="group.children.length">
          <SettingsMenuButton
            v-for="button in group.children"
            :key="button.id"
            :button="button"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import PageHeader from "@/layout/PageHeader.vue";
import SettingsMenuButton from "@/features/ui/common/SettingsMenuButton.vue";
import {mapGetters} from "vuex";

export default {
  name: "PageSettingsAccount",
  components: {
    SettingsMenuButton,
    PageHeader,
  },
  data() {
    return {
      buttonListLinkedAccounts: [
        // hide on pre-mvp
        // {
        //   id: 1,
        //   name: this.$t("general.settingsAccount.button.twitter"),
        //   addition: "",
        //   action: () => this.$router.push('/settings/twitter'),
        // },
        // {
        //   id: 2,
        //   name: this.$t("general.settingsAccount.button.google"),
        //   addition: "",
        //   action: () => this.$router.push('/settings/google'),
        // },
      ],
      buttonListSecurity: [
        {
          id: 1,
          name: this.$t("general.settingsAccount.button.password"),
          addition: "",
          action: () => this.$router.push('/settings/password'),
        },
        {
          id: 2,
          name: this.$t("general.settingsAccount.button.auth"),
          addition: "",
          action: () => {},
        },
      ],
      buttonListOther: [
        {
          id: 1,
          name: this.$t("general.settingsAccount.button.deleteAcc"),
          addition: "",
          action: () => this.$router.push('/settings/delete-account'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ user: "user" }),
    groups() {
      return [
        {
          id: 1,
          name: this.$t("general.settingsAccount.groupName.general"),
          children: this.buttonListGeneral,
        },
        {
          id: 2,
          name: this.$t("general.settingsAccount.groupName.linkedAccounts"),
          children: this.buttonListLinkedAccounts,
        },
        {
          id: 3,
          name: this.$t("general.settingsAccount.groupName.security"),
          children: this.buttonListSecurity,
        },
        {
          id: 4,
          name: this.$t("general.settingsAccount.groupName.other"),
          children: this.buttonListOther,
        },
      ];
    },

    buttonListGeneral() {
      return [
        {
          id: 1,
          name: this.$t("general.settingsAccount.button.username"),
          addition: `@${this.user?.username}` || "",
          action: () =>  this.$router.push('/settings/username'),
        },
        {
          id: 2,
          name: this.$t("general.settingsAccount.button.email"),
          addition: `${this.user?.email}` || "",
          action: () => this.$router.push('/settings/email'),
        },
        {
          id: 3,
          name: this.$t("general.settingsAccount.button.phone"),
          addition: "",
          action: () => this.$router.push('/settings/phone'),
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.page-settings-menu {
  margin-bottom: em(25);
  padding-left: 0;

  &__group-title {
    padding: em(21) 0;
  }

  &__title {
    margin-top: em(21);
  }

  &__settings {
    margin-left: em(-15);
    margin-right: em(-15);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__font {
    font-family: $font-default;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &--group-title {
      color: $black;
      font-size: em(18);
      font-weight: 500;
    }
  }

  &__icon {
    width: em(17);
    height: em(17);
    color: $app-gray-5;
  }
}
</style>
